
// import { socketAutentica, socketDeAutentica } from './CodeeSocket';
// IMPLEMENTAR SISTEMA DE SOCKET // 
export function getToken() {
    return localStorage.getItem('user_token');
}

export function setCredentials(loginUser) {
    loginUser.token = "Bearer " + loginUser.token;
    localStorage.setItem('user_id', loginUser.id);
    localStorage.setItem('user_name', loginUser.nomeAssociacao);
    localStorage.setItem('token', loginUser.token);
    localStorage.setItem('profile', JSON.stringify(loginUser.profile));
    // socketAutentica(loginUser.body.token);

    return loginUser;
}

export function deCredential(history = false) {
    const userId = localStorage.getItem('user_id');
    localStorage.removeItem('user_id', userId);    
    localStorage.removeItem('user_name');    
    localStorage.removeItem('token')

    //socketDeAutentica(userId);

}